import { get, post } from "./httpservices";
import { store } from "../store/store";
import Cookies from "js-cookie";
import { AXIOS } from "../plugins/axios";
import { router } from "../router/index";

export async function userprofile() {
    let resp;
    try {
        resp = await get("admin/auth/user");
        store.dispatch("authUser", resp.data.data);
    } catch (e) {
        Cookies.remove("admin_access_token");
        Cookies.remove("admin_refresh_token");
        Cookies.remove("admin_fcm_id");
        store.commit("authLogout");

        if (router.currentRoute.fullPath !== "/login") {
            router.push({ path: "/login" });
        }
    }
}

export async function userlogout() {
    try {
        await post("admin/auth/logout");
        store.dispatch("authLogout");
    } catch (e) {
        return e.response;
    }
}

export async function userProfileUpdate(data) {
    let resp;
    try {
        resp = await post("admin/auth/profile-update", data);
        return resp.data.data;
    } catch (e) {
        console.log(e);
        router.push({ path: "/500" });
    }
}

export async function passwordChange(data) {
    let resp;
    try {
        resp = await post("admin/auth/change-password", data);
        return resp.data;
    } catch (e) {
        console.log(e);
        router.push({ path: "/500" });
    }
}

export async function storeFCMId(fcm_id) {
    let resp;
    try {
        resp = await post("admin/auth/fcm-token", fcm_id);
        return resp.data;
    } catch (e) {
        return e.response;
    }
}

export async function buildIcons() {
    return new Promise((resolve, reject) => {
        AXIOS.get("icon-url")
            .then((response) => {
                store.commit("buildIcons", response.data);
                resolve(response);
            })
            .catch((error) => {
                console.log(error);
                reject;
            });
    });
}

export async function userPasswordReset(data) {
    let resp;
    try {
        resp = await post("auth/forget-password", data);
        return resp;
    } catch (e) {
        console.log(e);
        router.push({ path: "/500" });
    }
}
