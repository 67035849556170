"use strict";

import axios from "axios";
import Cookies from "js-cookie";
import { store } from '../store/store';
import { Base_URL } from '../config/variable';

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

//axios.defaults.baseURL = 'http://192.168.2.226:8080/api/';

let bearer = Cookies.get('admin_access_token');

if (bearer) {
  axios.defaults.headers.common['Authorization'] = bearer
}

let refresh_bearer = Cookies.get('admin_refresh_token');

if (refresh_bearer) {
  axios.defaults.headers.common['X-Authorization'] = refresh_bearer
}


axios.defaults.headers.post['Content-Type'] = 'application/json, application/x-www-form-urlencoded, charset=UTF-8';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Authorization, X-Authorization,Content-Type, Accept';


export const AXIOS = axios.create({
  baseURL: Base_URL + '/api/',
  timeout: 10000,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

AXIOS.interceptors.request.use(config => {
  store.commit('show');
  return config;
}, error => {
  store.commit('hide');
  return Promise.reject(error);
});

AXIOS.interceptors.response.use(response => {
  store.commit('hide');
  return response;
}, error => {
  store.commit('hide');
  return Promise.reject(error);
});