import { get, post } from './httpservices';
import { store } from '../store/store';
import { router } from '../router/index';

export async function listsOfContractor(id) {
    let resp;
    try {
        resp = await get('admin/lists/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function listDetails(id) {
    let resp;
    try {
        resp = await get('admin/list/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}
