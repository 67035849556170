import { get, post } from './httpservices';
import { store } from '../store/store';
import { router } from '../router/index';

export async function listbidData(date, type) {
    let resp;
    try {
        resp = await get('admin/list-chart-data?date=' + date + '&filter_by=' + type);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function deliveryData(date, type) {
    let resp;
    try {
        resp = await get('admin/delivery-chart-data?date=' + date + '&filter_by=' + type);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}