const state = {
    namespaced: true,
    loading: false,
    background: false,
}

const actions = {
    show({ commit }) {
        commit("show");
    },
    hide({ commit }) {
        commit("hide");
    },
    background({ commit }){
        commit("background");
    }
}

const mutations = {
    show(state) {
        if(state.background === false){
            state.loading = true;
        }
    },
    hide(state) {
        state.loading = false;
        state.background = false;
    },
    background(state) {
        state.background = true;
    }
}

export default {
    state,
    actions,
    mutations,
}

