import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store/store";

Vue.use(VueRouter);

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: lazyLoad("auth/Login"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/",
    component: lazyLoad("menubars/Dashboard"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "",
        component: lazyLoad("dashboard/Dashboard"),
      },
      {
        path: "/home",
        name: "home",
        component: lazyLoad("dashboard/Dashboard"),
      },
      {
        path: "/contractors",
        component: lazyLoad("contractor/Contractor"),
        children: [
          {
            path: "",
            component: lazyLoad("contractor/ContractorList"),
          },
          {
            path: "/contractors/lists/:id",
            name: "contractor-list",
            component: lazyLoad("common/List"),
          },
          {
            path: "/contractors/bids/:id",
            component: lazyLoad("common/Bid"),
          },
          {
            path: "/contractors/deliveries/:id",
            component: lazyLoad("common/Delivery"),
          },
        ],
      },
      {
        path: "/distributors",
        component: lazyLoad("distributor/Distributor"),
        children: [
          {
            path: "",
            component: lazyLoad("distributor/DistributorList"),
          },
          {
            path: "/distributors/bids/:id",
            component: lazyLoad("common/Bid"),
          },
          {
            path: "/distributors/deliveries/:id",
            component: lazyLoad("common/Delivery"),
          },
        ],
      },
      {
        path: "/distributor-invite",
        name: "distributor-invite",
        component: lazyLoad("distributor/InviteDistributor"),
      },
      {
        path: "/contractor-create",
        name: "contractor-create",
        component: lazyLoad("contractor/createContractor"),
      },
      {
        path: "/settings",
        name: "settings",
        component: lazyLoad("setting/Setting"),
      },
    ],
  },
  {
    path: "/sidemenu",
    name: "sidemenu",
    component: lazyLoad("menubars/Sidemenu"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/404",
    component: lazyLoad("404"),
  },
  {
    path: "/500",
    component: lazyLoad("500"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

export const router = new VueRouter({
  mode: "history",
  routes,
});
