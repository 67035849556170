import moment from 'moment-timezone'


export function dateFormat(data) {
	if (data) {
		return moment(data).format('M-D-Y')
	}
}

export function timeFormat(data) {
	if (data != '') {
		return moment(data, 'HH:mm:ss').format('hh:mm A')
		// var new_date = moment.utc(utcTime).format('HH:mm:ss')
		// return moment.tz(new_date, 'hh:mm A', moment.tz.guess())
	} else {
		return ''
	}
}

// export function timeFormat(data) {
//     if (data != "") {
//         var d = moment(String(data)).format("HH:mm");
//         let utcTime = d === "Invalid date" ? data : d;
//         return moment
//             .utc(utcTime, "HH:mm")
//             .local()
//             .format("hh:mm A");
//     } else {
//         return "";
//     }
// }

export function covLocalTime(date, time) {
	if (date && time) {
		let utcDate = moment.utc(date + ' ' + time)
		return moment
			.utc(utcDate, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('hh:mm A')
	}
}

export function covLocalDate(date, time) {
	if (date && time) {
		let utcDate = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss')
		return moment
			.utc(utcDate, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('M-D-Y')
	}
}


export function localTimeFromDateTime(data) {
	if (data) {
		return moment
			.utc(data, 'YYYY-MM-DD HH:mm:ss')
			.tz(moment.tz.guess())
			.format('M-D-Y hh:mm A')
	}
}

export function short_months(dt) {
    Date.shortMonths = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    return Date.shortMonths[dt.getMonth()];
}

export function monthFormat(data) {
    if (data) {
        var formatted_date = moment(data, "YYYY-MM-DDTHH:mm:ssZ").format(
          "MMM YYYY "
        );
        // let current_datetime = new Date(data);
        // let formatted_date =
        //     short_months(current_datetime) +
        //     "-" +
        //     current_datetime.getFullYear();
        return formatted_date;
    }
}

export function dayFormat(data) {
    if (data) {
        var current_datetime = moment(data, "YYYY-MM-DDTHH:mm:ssZ").format(
          "MMM DD"
        );
        // let current_datetime = new Date(data);
        // var dd = moment(String(current_datetime)).format("DD");
        // var mm = moment(String(current_datetime)).format("MMM");
        // let formatted_date = short_months(current_datetime) + "-" + dd;
        // let formatted_date = mm + "-" + dd;
        return current_datetime;
    }
    }
    
