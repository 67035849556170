import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import loader from './modules/loader'
import icons from './modules/icons'
import barchart from './modules/barchart'
import contractorlist from './modules/contractorlist'
import list from './modules/list'
import bid from './modules/bid'
import delivery from './modules/delivery'
import distributorlist from './modules/distributorlist'
import dashboard from './modules/dashboard'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        auth,
        loader,
        icons,
        barchart,
        contractorlist,
        list,
        bid,
        delivery,
        distributorlist,
        dashboard
    }
});

//  store;