import { get, post, put } from './httpservices';
import { store } from '../store/store';
import { router } from '../router/index';

export async function googlePlaces(data) {
    let resp;
    store.commit('background');
    try {
        resp = await post('google-places', data);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function emailCheck(email, type) {
    let resp;
    store.commit('background');
    try {
        resp = await get('email-check/' + email + '/' + type);
        if (resp.data.data.email === false) {
            return false;
        }
        else if (resp.data.data.email === true) {
            return true
        }
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function inviteDistributor(data) {
    let resp;
    try {
        resp = await post('admin/invite-distributor', data);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function distributorList() {
    let resp;
    try {
        resp = await get('admin/distributors');
        // console.log(resp.data)
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function distributorUpdate(id, data) {
    let resp;
    try {
        resp = await put('admin/user/' + id, data);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function distributorCompany(id) {
    let resp;
    try {
        resp = await get('admin/distributor/company/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}