import { get, post, put } from "./httpservices";

import { router } from "../router/index";
import { store } from "../store/store";

export async function contractorList() {
  let resp;
  try {
    resp = await get("admin/contractors");
    return resp.data;
  } catch (e) {
    console.log(e);
    router.push("/500");
  }
}

export async function inviteContractor(data) {
  let resp;
  try {
    resp = await post("admin/invite-contractor", data);
    return resp.data;
  } catch (e) {
    console.log(e);
    router.push("/500");
  }
}

export async function contractorUpdate(id, data) {
  let resp;
  try {
    resp = await put("admin/user/" + id, data);
    return resp.data;
  } catch (e) {
    console.log(e);
    router.push("/500");
  }
}

export async function contractorExport() {
  let resp;
  try {
    resp = await get("admin/contractors");
    return resp.data;
  } catch (e) {
    console.log(e);
    // router.push('/500');
  }
}

export async function getPlans() {
  let resp;
  try {
    resp = await get("subscriptions-plans");
    return resp.data;
  } catch (e) {
    console.log(e);
    // router.push('/500');
  }
}
