import { get, post, put } from './httpservices';
import { store } from '../store/store';
import { router } from '../router/index';

export async function deliveriesOfContractor(id) {
    let resp;
    try {
        resp = await get('admin/contractor/deliveries/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function deliveriesOfCompany(id) {
    let resp;
    try {
        resp = await get('admin/company/deliveries/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function deliveryDetails(id) {
    let resp;
    try {
        resp = await get('admin/delivery/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function changeDeliveryStatus(id, data) {
    let resp;
    try {
        resp = await put('admin/delivery/' + id, data);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function deliveryExport() {
  let resp;
  try {
    resp = await get("admin/contractor/deliveries");
    return resp.data;
  } catch (e) {
    console.log(e);
    // router.push('/500');
  }
}
