<template>
	<v-app style="background:#F7F9FC">
		<v-overlay
			:absolute="absolute"
			:opacity="opacity"
			:value="show"
			:z-index="zIndex"
			color="rgba(247, 249, 252, 0.46)"
		>
			<img :src="require('@/assets/loader.gif')" style="height:65px;" />
		</v-overlay>
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
	</v-app>
</template>

<script>
import { userprofile, buildIcons } from "./services/authservices";
import Cookies from "js-cookie";
import { Base_URL } from "./config/variable";
// import { getDeviceId } from "./services/firebase";
import "./plugins/components";
// import "./services/firebase";
import "./plugins/vuefilter";
import "./scss/_variables.scss";
import "./scss/mediaquery.scss";

export default {
	data() {
		return {
			absolute: false,
			opacity: 0.2,
			zIndex: 9999
		};
	},

	computed: {
		show() {
			return this.$store.state.loader.loading === true ? true : false;
		}
	},

	methods: {
		hide() {
			this.$store.commit("show");
		}
	},

	mounted() {
		buildIcons();
		userprofile();
		// getDeviceId();
	}
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>