import { AXIOS } from "../plugins/axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { store } from "../store/store";
import { router } from "../router/index";
// axios.defaults.baseURL = window.location.protocol + '//' + window.location.host + '/api';

async function token_refresh() {
    store.commit("errorMessage", "");

    const access_token = AXIOS.defaults.headers.common["Authorization"];
    let decoded = jwt_decode(access_token);
    if (decoded.exp - 60 < Math.floor(new Date().getTime() / 1000)) {
        let resp;
        try {
            resp = await AXIOS.post("admin/auth/refresh");
        } catch (error) {
            resp = error.response;
        }
        if (resp === undefined) {
            router.push({ path: "/500" });
        } else if (resp.status === 200) {
            let access_token = resp.data.access_token;
            store.dispatch("authRequest", { access_token });
            return true;
        } else if (resp.status === 404 || resp.status === 500) {
            Cookies.remove("admin_access_token");
            Cookies.remove("admin_refresh_token");
            store.commit("authLogout");

            router.push("/login");
        } else if (error.response.status === 401) {
            if (
                error.response.data.message === "token_invalid" ||
                error.response.data.message === "token_expired" ||
                error.response.data.message === "user_not_found"
            ) {
                store.dispatch("authLogout");
            }
            store.commit("errorMessage", error.response.data.message);
        } else if (error.response.status === 400) {
            if (
                error.response.data.message ===
                "token_not_provided, token_absent"
            ) {
                store.dispatch("authLogout");
            }
            store.commit("errorMessage", error.response.data.message);
        }
    }
}

export async function get(url) {
    await token_refresh();

    return new Promise((resolve, reject) => {
        AXIOS.get(url)
            .then((response) => {
                if (
                    response.status === 200 &&
                    !response.data.dataset &&
                    !response.data.data
                ) {
                    store.commit("errorMessage", response.data.message);
                }
                resolve(response);
            })
            .catch((error) => {
                if (error.response === undefined) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 404) {
                    router.push({ path: "/404" });
                } else if (error.response.status === 500) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 401) {
                    if (
                        error.response.data.message === "token_invalid" ||
                        error.response.data.message === "token_expired" ||
                        error.response.data.message === "user_not_found"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                } else if (error.response.status === 400) {
                    if (
                        error.response.data.message ===
                        "token_not_provided, token_absent"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                }
                reject;
            });
    });
}

export async function post(url, data) {
    await token_refresh();

    return new Promise((resolve, reject) => {
        AXIOS.post(url, data)
            .then((response) => {
                if (
                    response.status === 200 &&
                    !response.data.dataset &&
                    !response.data.data
                ) {
                    store.commit("errorMessage", response.data.message);
                }
                resolve(response);
            })
            .catch((error) => {
                if (error.response === undefined) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 404) {
                    router.push({ path: "/404" });
                } else if (error.response.status === 500) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 422) {
                    store.commit("errorMessage", error.response.data.message);
                } else if (error.response.status === 401) {
                    if (
                        error.response.data.message === "token_invalid" ||
                        error.response.data.message === "token_expired" ||
                        error.response.data.message === "user_not_found"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                } else if (error.response.status === 400) {
                    if (
                        error.response.data.message ===
                        "token_not_provided, token_absent"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                }
                reject;
            });
    });
}

export async function put(url, data) {
    await token_refresh();

    return new Promise((resolve, reject) => {
        AXIOS.put(url, data)
            .then((response) => {
                if (
                    response.status === 200 &&
                    !response.data.dataset &&
                    !response.data.data
                ) {
                    store.commit("errorMessage", response.data.message);
                }
                resolve(response);
            })
            .catch((error) => {
                if (error.response === undefined) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 404) {
                    router.push({ path: "/404" });
                } else if (error.response.status === 500) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 422) {
                    store.commit("errorMessage", error.response.data.message);
                } else if (error.response.status === 401) {
                    if (
                        error.response.data.message === "token_invalid" ||
                        error.response.data.message === "token_expired" ||
                        error.response.data.message === "user_not_found"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                } else if (error.response.status === 400) {
                    if (
                        error.response.data.message ===
                        "token_not_provided, token_absent"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                }
                reject;
            });
    });
}

export async function destory(url) {
    await token_refresh();

    return new Promise((resolve, reject) => {
        AXIOS.delete(url)
            .then((response) => {
                if (
                    response.status === 200 &&
                    !response.data.dataset &&
                    !response.data.data
                ) {
                    store.commit("errorMessage", response.data.message);
                }
                resolve(response);
            })
            .catch((error) => {
                if (error.response === undefined) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 404) {
                    router.push({ path: "/404" });
                } else if (error.response.status === 401) {
                    if (
                        error.response.data.message === "token_invalid" ||
                        error.response.data.message === "token_expired" ||
                        error.response.data.message === "user_not_found"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                } else if (error.response.status === 500) {
                    router.push({ path: "/500" });
                } else if (error.response.status === 400) {
                    if (
                        error.response.data.message ===
                        "token_not_provided, token_absent"
                    ) {
                        store.dispatch("authLogout");
                    }
                    store.commit("errorMessage", error.response.data.message);
                }
                reject;
            });
    });
}
