import { listbidData, deliveryData } from "../../services/chartdata";
import { dayFormat, monthFormat } from "../../services/commonservice";

const state = {
    myChart1: null,

    myChart2: null,

    list_bid_data: {    
        date: new Date().toISOString().substr(0, 7),
        filter_type: 'month',

        label: [
            "0"
        ],
        list_data: [
            "0"
        ],
        bid_data: [
            "0"
        ],
        accepted_bid_data: [
            "0"
        ]
    },

    delivery_data: {
        date: new Date().toISOString().substr(0, 7),
        filter_type: 'month',

        label: [
            "0"
        ],
        deliveries: [
            "0"
        ]
    }
}

const getters = {
    getListBidLables: (state) => {
        return state.list_bid_data.label;
    },

    getListBidBids: (state) => {
        return state.list_bid_data.bid_data;
    },

    getListBidLists: (state) => {
        return state.list_bid_data.list_data;
    },

    getListBidAcceptedBids: (state) => {
        return state.list_bid_data.accepted_bid_data;
    },

    getDeliveryLabels: (state) => {
        return state.delivery_data.label;
    },

    getDeliveryDataDeliveries: (state) => {
        return state.delivery_data.deliveries;
    },
    
}

const actions = {
    async listbidChart ({commit}, payload) {
        let data_ob = await listbidData(payload.date, payload.type);
        commit('listbidChart', {data_ob, payload});
    },

    async deliveryChart ({commit}, payload) {
        let data_ob = await deliveryData(payload.date, payload.type);
        commit('deliveryChart', {data_ob, payload});
    }
}

const mutations = {
    listbidChart: (state, {data_ob, payload}) => {
        state.list_bid_data.date = payload.date;
        state.list_bid_data.filter_type = payload.type;
        state.list_bid_data.label = [];
        state.list_bid_data.list_data = [];
        state.list_bid_data.bid_data = [];
        state.list_bid_data.accepted_bid_data = [];

        data_ob.dataset.forEach(data => {
            if(data_ob.dataset.length > 12){
                var label = dayFormat(data.date); 
            } else {
                var label = monthFormat(data.date); 
            }

            state.list_bid_data.label.push(label);
            state.list_bid_data.list_data.push(data.lists);
            state.list_bid_data.bid_data.push(data.bids);
            state.list_bid_data.accepted_bid_data.push(data.accepted_bids);
        });
    },

    deliveryChart: (state, {data_ob, payload}) => {
        state.delivery_data.date = payload.date;
        state.delivery_data.filter_type = payload.type;
        state.delivery_data.label = [];
        state.delivery_data.deliveries = [];

        data_ob.dataset.forEach(data => {
            if(data_ob.dataset.length > 12){
                var label = dayFormat(data.date); 
            } else {
                var label = monthFormat(data.date); 
            }

            state.delivery_data.label.push(label);
            state.delivery_data.deliveries.push(data.deliveries);
        });
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}

