import { deliveriesOfContractor, deliveriesOfCompany } from "../../services/deliveryservice";
import { dateFormat, timeFormat, covLocalTime } from "../../services/commonservice";

const state = {
    delivery_data: []
}

const getters = {
    getDelivery: (state) => {
        return state.delivery_data;
    },

    getDeliveryById: (state) => (delivery_id) => {
        return state.delivery_data.find(delivery => delivery.delivery_id === delivery_id);
    },

    getDeliveryStatus: (state) => (delivery_id) => {
        if(delivery_id){
            let data = state.delivery_data.find(delivery => delivery.delivery_id === delivery_id);
            return data.status;
        }
    }
}

const actions = {
    async deliveryOfCont ({commit},payload) {
        let data = await deliveriesOfContractor(payload);
        commit('deliveryData', data);
    },

    async deliveryOfDist ({commit},payload) {
        let data = await deliveriesOfCompany(payload);
        commit('deliveryData', data);
    },

    async changeDeliveryStatus ({commit},payload) {
        commit('changeDeliveryStatus', payload);
    }
}

const mutations = {
    deliveryData: (state, payload) => {
        state.delivery_data = [];
      
        if(payload.dataset.length > 0){
            payload.dataset.forEach(data => {
                let ob = {
                    delivery_id: data.delivery_id,
                    company_name: data.distributor_company.name,
                    date_of_delivery: dateFormat(data.bid.date_of_delivery),
                    time_of_delivery: covLocalTime(data.bid.date_of_delivery,data.bid.start_time) + " - " + covLocalTime(data.bid.date_of_delivery, data.bid.end_time),
                    distance: parseFloat(data.bid.distance).toFixed(2),
                    status: data.status,
                    pickup: data.clist.pickup,
                    total_amount: data.bid.total_amount,
                }

                state.delivery_data.push(ob);
            });
        }
    },

    changeDeliveryStatus: (state, payload) => {
        let item = state.delivery_data.find(item => item.delivery_id === payload.delivery_id);
        Object.assign(item, payload);
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}

