import { listsOfContractor } from "../../services/listservice";

const state = {
    list_data: []
}

const getters = {
    getList: (state) => {
        return state.list_data;
    },

    getListById: (state) => (list_id) => {
        if(state.list_data.length === 0){
            return true;
        }

        return state.list_data.find(list => list.list_id === list_id);
    },
}


const actions = {
    async listOfCont ({commit},payload) {
        let data = await listsOfContractor(payload);
        commit('listOfCont', data);
    }
}

const mutations = {
    listOfCont: (state, payload) => {
        state.list_data = [];
        
        if(payload.dataset.length > 0){
            payload.dataset.forEach(data => {
                let ob = {
                    list_id: data.list_id,
                    category_id: data.category_id,
                    list_name: data.list_name,
                    address: data.address,
                    status: data.status,
                    total_bids: data.bids_count,
                    active_bids: data.active_bids_count,
                }

                state.list_data.push(ob);
            });
        }
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}

