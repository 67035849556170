const state = {
    icons: {

    }
}

const mutations = {
    buildIcons: (state, icons) => {
        state.icons = icons
    }
}

export default {
    state,
    mutations,
}
