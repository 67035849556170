import { bidsOfList, bidsOfCompany } from "../../services/bidservice";

const state = {
    bid_data: []
}

const getters = {
    getBid: (state) => {
        return state.bid_data;
    },

    getBidById: (state) => (bid_id) => {
        let data = state.bid_data.find(list => list.bid_id === bid_id);
        return data;
    },
}

const actions = {
    async listBids ({commit},payload) {
        let data = await bidsOfList(payload);
        commit('listBids', data);
    },

    async companyBids ({commit},payload) {
        let data = await bidsOfCompany(payload);
        commit('listBids', data);
    }
}

const mutations = {
    listBids: (state, payload) => {
        state.bid_data = [];
        
        if(payload.dataset.length > 0){
            payload.dataset.forEach(data => {
                let ob = {
                    bid_id: data.bid_id,
                    company_id: data.distributor_company.company_id,
                    company_name: data.distributor_company.name,
                    distance: parseFloat(data.distance).toFixed(2),
                    status: data.status,
                    sub_total: data.sub_total,
                    shipping: data.shipping,
                    service_fee: data.service_fee,
                    sales_tax: data.sales_tax,
                    tax_percentage: data.tax_percentage,
                    total_amount: data.total_amount,
                }
               
                state.bid_data.push(ob);
            });
        }
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}

