import { get, post } from './httpservices';
import { store } from '../store/store';
import { router } from '../router/index';

export async function bidsOfList(id) {
    let resp;
    try {
        resp = await get('admin/list/bids/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function bidsOfCompany(id) {
    let resp;
    try {
        resp = await get('admin/company/bids/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function bidDetails(id) {
    let resp;
    try {
        resp = await get('admin/bid/' + id);
        return resp.data;
    }
    catch (e) {
        console.log(e);
        router.push('/500');
    }
}

export async function bidExport() {
  let resp;
  try {
    resp = await get("admin/bid");
    return resp.data;
  } catch (e) {
    console.log(e);
    // router.push('/500');
  }
}