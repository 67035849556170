import { contractorList } from "../../services/contractorservice";
import { localTimeFromDateTime } from "../../services/commonservice";

const state = {
    contractor_list: [],
};

const getters = {
    getContractor: (state) => {
        let data = state.contractor_list;
        return data;
    },

    getContractorById: (state) => (id) => {
        if (state.contractor_list.length === 0) {
            return true;
        }

        return state.contractor_list.find((contractor) => contractor.id === id);
    },
};

const actions = {
    contractorUpdate: ({ commit }, payload) => {
        commit("contractorUpdate", payload);
    },

    contractorDelete: ({ commit }, payload) => {
        commit("contractorUpdate", payload);
    },

    contractorSuspend: ({ commit }, payload) => {
        commit("contractorUpdate", payload);
    },

    async contList({ commit }) {
        let data = await contractorList();
        commit("contList", data);
    },
};

const mutations = {
    contractorUpdate: (state, payload) => {
        let item = state.contractor_list.find((item) => item.id === payload.id);
        Object.assign(item, payload);
    },

    contList: (state, payload) => {
        state.contractor_list = [];

        if (payload.dataset.length > 0) {
            payload.dataset.forEach((data) => {
                let ob = {
                    id: data.id,
                    fullname: data.fullname,
                    email: data.email,
                    phone: data.phone === null ? null : data.phone.length > 0 ? data.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") : null,
                    status: data.status,
                    project_count: data.projects_count,
                    list_count: data.clists_count,
                    delivery_count: data.contractor_deliveries_count,
                    total_payment: data.payment,
                    total_mmb_fee: data.service_fee,
                    subscription_id: data.subscription?.subscription_id,
                    team_users: data.subscription?.team_users,
                    profile_link: data.profile_link,
                    last_login: data.last_login ? new Date(localTimeFromDateTime(data.last_login)).getTime() : data.last_login,
                };

                state.contractor_list.push(ob);
            });
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
