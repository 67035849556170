import { dashboardData } from "../../services/dashboardservice";
import { dayFormat, monthFormat } from "../../services/commonservice";

const state = {
    dashboar_data: {
        date: new Date().toISOString().substr(0, 7),
        filter_type: "",
        transaction: "0",
        payment: "0",
        sales_tax: "0",
        profit: "0",
        contractors: "0",
        distributors: "0",
        authorize_users: "0",
        crews: "0",
        projects: "0",
        lists: "0",
        bids: "0",
        deliveries: "0",
    },
};

const getters = {
    getDashboardInfo: (state) => {
        return state.dashboar_data;
    },
};

const actions = {
    async dashboardInfo({ commit }, payload) {
        let data = await dashboardData(payload.date, payload.type);
        commit("dashboardInfo", data);
    },
};

const mutations = {
    dashboardInfo: (state, payload) => {
        state.dashboar_data.transaction = payload.data.transaction;
        state.dashboar_data.payment = payload.data.payment;
        state.dashboar_data.sales_tax = payload.data.sales_tax;
        state.dashboar_data.profit = payload.data.profit;
        state.dashboar_data.contractors = payload.data.contractors;
        state.dashboar_data.distributors = payload.data.distributors;
        state.dashboar_data.authorize_users = payload.data.authorize_users;
        state.dashboar_data.crews = payload.data.crews;
        state.dashboar_data.projects = payload.data.projects;
        state.dashboar_data.lists = payload.data.lists;
        state.dashboar_data.bids = payload.data.bids;
        state.dashboar_data.deliveries = payload.data.deliveries;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
