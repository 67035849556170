import { distributorList } from "../../services/distributorservice";
import { localTimeFromDateTime } from "../../services/commonservice";

const state = {
    distributor_list: [],
};

const getters = {
    getDistributor: (state) => {
        return state.distributor_list;
    },

    getDistributorById: (state) => (id) => {
        if (state.distributor_list.length === 0) {
            return true;
        }
        return state.distributor_list.find((distributor) => distributor.id === id);
    },

    getDistributorByCompanyId: (state) => (id) => {
        if (state.distributor_list.length === 0) {
            return true;
        }
        return state.distributor_list.find((distributor) => distributor.company_id === id);
    },
};

const actions = {
    distributorUpdate: ({ commit }, payload) => {
        commit("distributorUpdate", payload);
    },

    distributorDelete: ({ commit }, payload) => {
        commit("distributorUpdate", payload);
    },

    distributorSuspend: ({ commit }, payload) => {
        commit("distributorUpdate", payload);
    },

    async distList({ commit }) {
        let data = await distributorList();
        commit("distList", data);
    },
};

const mutations = {
    distributorUpdate: (state, payload) => {
        let item = state.distributor_list.find((item) => item.id === payload.id);
        Object.assign(item, payload);
    },

    distList: (state, payload) => {
        state.distributor_list = [];

        if (payload.dataset.length > 0) {
            payload.dataset.forEach((data) => {
                let ob = {
                    id: data.id,
                    company_id: data.company_id,
                    fullname: data.fullname,
                    email: data.email,
                    phone: data.phone === null ? null : data.phone.length > 0 ? data.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") : null,
                    status: data.status,
                    bid_count: data.bids_count,
                    winning_bid_count: data.distributor_deliveries_count,
                    delivery_count: data.distributor_deliveries_count,
                    total_revenue: data.revenue,
                    total_mmb_fee: data.service_fee,
                    profile_link: data.profile_link,
                    last_login: data.last_login ? new Date(localTimeFromDateTime(data.last_login)).getTime() : data.last_login,
                };

                state.distributor_list.push(ob);
            });
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
