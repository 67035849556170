import Cookies from "js-cookie";
import { router } from "../../router/index";
import { AXIOS } from "../../plugins/axios";

const state = {
  access_token: Cookies.get("admin_access_token") || "",
  user_data: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
  },
  status: "",
  hasLoadedOnce: false,
  fcm: "",
  error: "",
  alert: {
    show: false,
    message: "",
    type: "",
  },
};

const getters = {
  isAuthenticated: (state) => !!state.access_token,
  isAuthorization: (state) => state.user_data.role || "",
  authStatus: (state) => state.status,

  getUserData: (state) => {
    return state.user_data;
  },
};

const actions = {
  authRequest: ({ commit }, payload) => {
    let access_token = "Bearer " + payload.access_token;
    Cookies.set("admin_access_token", access_token, { expires: 90 });
    AXIOS.defaults.headers.common["Authorization"] = access_token;
    commit("authSuccess", access_token);
  },
  authLogout: ({ commit }) => {
    Cookies.remove("admin_access_token");
    Cookies.remove("admin_refresh_token");
    Cookies.remove("admin_fcm_id");
    commit("authLogout");
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/login";
  },
  showAlert: ({ commit }, payload) => {
    commit("showAlert", payload);
  },
  authUser: ({ commit }, payload) => {
    commit("authUser", payload);
  },
};

const mutations = {
  authRequest: (state) => {
    state.status = "loading";
  },
  authUser: (state, user) => {
    let first_name;
    let last_name;
    if (user.fullname != null) {
      first_name = user.fullname.split(" ").slice(0, -1).join(" ");
      last_name = user.fullname.split(" ").slice(-1).join(" ");
    }

    state.user_data.first_name = first_name;
    state.user_data.last_name = last_name;
    state.user_data.email = user.email;
    state.user_data.phone =
      user.phone === null
        ? null
        : user.phone.length > 0
        ? user.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3")
        : null;
    state.user_data.role = user.role;

    state.fcm = user.fcm;
  },
  authSuccess: (state, access_token) => {
    state.status = "success";
    state.access_token = access_token;
    state.hasLoadedOnce = true;
  },
  authLogout: (state) => {
    state.access_token = "";
    state.user_data.first_name = "";
    state.user_data.last_name = "";
    state.user_data.email = "";
    state.user_data.phone = "";
    state.user_data.role = "";
    state.user_data.company_id = "";
  },
  errorMessage: (state, payload) => {
    state.error = payload;
  },
  showAlert: (state, payload) => {
    state.alert.show = payload.show;
    state.alert.message = payload.message;
    state.alert.type = payload.type;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
