import Vue from 'vue'
import './plugins/axios'
import { router } from './router/index'
import { store } from './store/store'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import Cookies from "js-cookie";
// require('dotenv').config({ override: true })



Vue.config.productionTip = false

import App from './App';

Vue.use(VueTheMask)

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: h => h(App)
})

router.beforeEach((to, from, next) => {
  if (
      to.name !== "login" &&
      Cookies.get("admin_access_token") == undefined
  ) {
      store.dispatch("authLogout");
      next("/login");
  } else {
      next();
  }
});